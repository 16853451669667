import { Dialog, Transition } from '@headlessui/react';
import { DownloadIcon } from '@heroicons/react/solid';
import { forwardRef, Fragment, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

type IDownloadInputProps = {
  callback: (
    status: boolean,
    from_date?: Date,
    to_date?: Date
  ) => Promise<void>;
  title: string;
  show: boolean;
  downloadWithoutDate: boolean;
};

const CustomDateInput = forwardRef(
  ({ value, onClick, onChange }: any, ref: any) => (
    <input
      ref={ref}
      type="text"
      className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
      value={value}
      onClick={onClick}
      onChange={onChange}
    />
  )
);
CustomDateInput.displayName = 'CustomDateInput';

const DownloadInputs = ({
  callback,
  title,
  show,
  downloadWithoutDate,
}: IDownloadInputProps) => {
  const cancelButtonRef = useRef(null);

  const now = new Date();
  const year_ago = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );
  const [fromDate, setFromDate] = useState<Date>(year_ago);
  const [toDate, setToDate] = useState<Date>(now);
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => callback(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DownloadIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Télécharger {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {downloadWithoutDate ? null : (
                        <div>
                          <label
                            htmlFor="date"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Date de début
                          </label>
                          <div className="mt-1">
                            <DatePicker
                              selected={fromDate}
                              customInput={<CustomDateInput />}
                              onChange={(date: Date) => {
                                setFromDate(date);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {downloadWithoutDate ? null : (
                        <div>
                          <label
                            htmlFor="date"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Date de fin
                          </label>
                          <div className="mt-1">
                            <DatePicker
                              selected={toDate}
                              customInput={<CustomDateInput />}
                              onChange={(date: Date) => {
                                setToDate(date);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () =>
                      downloadWithoutDate
                        ? callback(true)
                        : callback(true, fromDate, toDate)
                    }
                  >
                    Télécharger
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => callback(false)}
                    ref={cancelButtonRef}
                  >
                    Annuler
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DownloadInputs;
