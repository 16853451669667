import { Menu, Transition } from '@headlessui/react';
import { signOut } from 'next-auth/react';
import { Fragment } from 'react';

import { classNames } from '@/utils/classNames';

type IProfileDropDownProps = {
  button: JSX.Element;
  mainClassName: string;
  itemsClassName: string;
};

const ProfileDropDown = (props: IProfileDropDownProps) => {
  return (
    <Menu as="div" className={props.mainClassName}>
      <div>{props.button}</div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={props.itemsClassName}>
          <div className="py-1">
            {/*  <Menu.Item> */}
            {/*    {({ active }) => ( */}
            {/*      <a */}
            {/*        href="#" */}
            {/*        className={classNames( */}
            {/*          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', */}
            {/*          'block px-4 py-2 text-sm' */}
            {/*        )} */}
            {/*      > */}
            {/*        View profile */}
            {/*      </a> */}
            {/*    )} */}
            {/*  </Menu.Item> */}
            {/*  <Menu.Item> */}
            {/*    {({ active }) => ( */}
            {/*      <a */}
            {/*        href="#" */}
            {/*        className={classNames( */}
            {/*          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', */}
            {/*          'block px-4 py-2 text-sm' */}
            {/*        )} */}
            {/*      > */}
            {/*        Settings */}
            {/*      </a> */}
            {/*    )} */}
            {/*  </Menu.Item> */}
            {/*  <Menu.Item> */}
            {/*    {({ active }) => ( */}
            {/*      <a */}
            {/*        href="#" */}
            {/*        className={classNames( */}
            {/*          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', */}
            {/*          'block px-4 py-2 text-sm' */}
            {/*        )} */}
            {/*      > */}
            {/*        Notifications */}
            {/*      </a> */}
            {/*    )} */}
            {/*  </Menu.Item> */}
            {/* </div> */}
            {/* <div className="py-1"> */}
            {/*  <Menu.Item> */}
            {/*    {({ active }) => ( */}
            {/*      <a */}
            {/*        href="#" */}
            {/*        className={classNames( */}
            {/*          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', */}
            {/*          'block px-4 py-2 text-sm' */}
            {/*        )} */}
            {/*      > */}
            {/*        Get desktop app */}
            {/*      </a> */}
            {/*    )} */}
            {/*  </Menu.Item> */}
            {/*  <Menu.Item> */}
            {/*    {({ active }) => ( */}
            {/*      <a */}
            {/*        href="#" */}
            {/*        className={classNames( */}
            {/*          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', */}
            {/*          'block px-4 py-2 text-sm' */}
            {/*        )} */}
            {/*      > */}
            {/*        Support */}
            {/*      </a> */}
            {/*    )} */}
            {/*  </Menu.Item> */}
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() => {
                    signOut();
                  }}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Déconnexion
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileDropDown;
