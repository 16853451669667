import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

type MainContextType = {
  query: string;
  setQuery?: Dispatch<SetStateAction<string>>;
};

export const MainContext = React.createContext<MainContextType>({
  query: '',
});
