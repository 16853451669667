import { Menu } from '@headlessui/react';
import {
  ChartBarIcon,
  GiftIcon,
  HomeIcon,
  MenuAlt1Icon,
  ReceiptTaxIcon,
  ShoppingCartIcon,
} from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
// import getConfig from 'next/config';
import { useSession } from 'next-auth/react';
import type { ReactNode } from 'react';
import React, { useState } from 'react';

import ProfileDropDown from '@/compoments/ProfileDropDown';
import SideBar from '@/compoments/SideBar';
import StaticSideBar from '@/compoments/StaticSideBar';
import { MainContext } from '@/context/main-context';
import type { NavigationItem } from '@/types/navigation';

// const { debug } = getConfig().publicRuntimeConfig;

type IMainProps = {
  meta: ReactNode;
  mainClass?: string;
  children: ReactNode;
};

const navigation: NavigationItem[] = [
  { name: 'Home', href: '/', icon: ChartBarIcon },
  { name: 'Produits', href: '/product', icon: HomeIcon },
  { name: 'Box', href: '/box', icon: GiftIcon },
  { name: 'Achats', href: '/purchase', icon: ShoppingCartIcon },
  { name: 'Ventes', href: '/sale', icon: ReceiptTaxIcon },
  { name: 'Catégories', href: '/category', icon: ReceiptTaxIcon },
];

const Main = (props: IMainProps) => {
  // useSession({ required: true });
  useSession({ required: false });
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [query, setQuery] = useState('');

  return (
    <div className="flex h-full">
      {/* Mobile sidebar */}
      <SideBar
        navigation={navigation}
        isOpen={isSidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      {/* Static sidebar for desktop */}
      <StaticSideBar navigation={navigation} />
      <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
        {/* Search header */}
        <div className="sticky top-0 z-10 flex h-16 shrink-0 border-b border-gray-200 bg-white xl:hidden">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 xl:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
            <div className="flex flex-1">
              <form className="flex w-full md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    name="search-field"
                    className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 focus:placeholder:text-gray-400 sm:text-sm"
                    placeholder="Search"
                    type="search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="flex items-center">
              <ProfileDropDown
                mainClassName="relative ml-3"
                itemsClassName="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                button={
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </Menu.Button>
                }
              />
            </div>
          </div>
        </div>
        <div className={props.mainClass ?? 'flex-1 overflow-y-auto'}>
          <MainContext.Provider
            value={{
              query,
              setQuery,
            }}
          >
            {props.children}
          </MainContext.Provider>
        </div>
      </div>
    </div>
  );
};

export { Main };
