import { Menu } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';

import ProfileDropDown from '@/compoments/ProfileDropDown';
import type { NavigationItem } from '@/types/navigation';
import { classNames } from '@/utils/classNames';

type IStaticSideBarProps = {
  navigation: NavigationItem[];
};

const StaticSideBar = (props: IStaticSideBarProps) => {
  const router = useRouter();
  return (
    <div className="hidden pt-6 lg:flex lg:shrink-0">
      <div className="flex w-64 flex-col">
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-100">
          <div className="flex shrink-0 items-center px-6">
            <img
              className="w-auto"
              src="https://media.jeveuxuncadeau.be/2021/11/small_png.png"
              alt="Workflow"
            />
          </div>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
            {/* User account dropdown */}
            <ProfileDropDown
              mainClassName="relative inline-block px-3 text-left"
              itemsClassName="absolute inset-x-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              button={
                <Menu.Button className="group w-full rounded-md bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  <span className="flex w-full items-center justify-between">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      {/* <img */}
                      {/*  className="h-10 w-10 shrink-0 rounded-full bg-gray-300" */}
                      {/*  src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" */}
                      {/*  alt="" */}
                      {/* /> */}
                      <span className="flex min-w-0 flex-1 flex-col">
                        <span className="truncate text-sm font-medium text-gray-900"></span>
                        <span className="truncate text-sm text-gray-500"></span>
                      </span>
                    </span>
                    <SelectorIcon
                      className="h-5 w-5 shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
              }
            />
            {/* Sidebar Search */}
            {/* <div className="mt-5 px-3"> */}
            {/*  <label htmlFor="search" className="sr-only"> */}
            {/*    Search */}
            {/*  </label> */}
            {/*  <div className="relative mt-1 rounded-md shadow-sm"> */}
            {/*    <div */}
            {/*      className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" */}
            {/*      aria-hidden="true" */}
            {/*    > */}
            {/*      <SearchIcon */}
            {/*        className="mr-3 h-4 w-4 text-gray-400" */}
            {/*        aria-hidden="true" */}
            {/*      /> */}
            {/*    </div> */}
            {/*    <input */}
            {/*      type="text" */}
            {/*      name="search" */}
            {/*      id="search" */}
            {/*      className="block w-full rounded-md border-gray-300 pl-9 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" */}
            {/*      placeholder="Search" */}
            {/*    /> */}
            {/*  </div> */}
            {/* </div> */}
            {/* Navigation */}
            <nav className="mt-6 px-3">
              <div className="space-y-1">
                {props.navigation.map((item) => {
                  const current = router.pathname === item.href;
                  return (
                    <Link key={item.name} href={item.href}>
                      <a
                        className={classNames(
                          current
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                        aria-current={current ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            current
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    </Link>
                  );
                })}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticSideBar;
